var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content content "},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"header-navbar-shadow"}),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-9 col-12 mb-2"},[_c('div',{staticClass:"row breadcrumbs-top profile__avatar"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title mb-0"},[_vm._v(" Assign peers - "+_vm._s(_vm.convo.title)),_c('br'),_c('small',{attrs:{"id":"time-in"}},[_vm._v("Deadline: "),_c('strong',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.convo.end_date_formatted)+" "+_vm._s(_vm.convo.end_hour_formatted))])])])])])])]),_c('div',{staticClass:"content-body content-body--senior-call"},[_c('CompleteTable',{attrs:{"sorted-field":_vm.sortedField,"data":_vm.applicants,"sort-mapping":_vm.sortMapping,"title":'ICREAs',"total":_vm.applicantsTotal,"fetch-path":'convo/promotionReport',"filter-path":'convo/promotionReport'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var field = ref.field;
return [_c('td',{staticClass:"text-end"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{attrs:{"data-feather":"more-vertical"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"btn-group dropup dropdown-icon-wrapper d-none d-sm-block"},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({name: 'evaluator.calls.evaluations.edit-peers', params: {
                    userPcmember: field,
                    convo: _vm.convo,
                  },})}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"edit"}}),_vm._v(" Manage peers ")])],1)])],1)]}},{key:"customfilters",fn:function(){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Researcher")]),_c('v-select',{attrs:{"label":"name","options":_vm.users,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'users/fetchFakeUsers')},"input":function($event){return _vm.saveFilter($event, 'user')}}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Area")]),_c('v-select',{attrs:{"label":"name","options":_vm.areas,"get-option-key":function (option) { return option.id; }},on:{"input":function($event){return _vm.saveFilter($event, 'area_name')}}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("ERC")]),_c('v-select',{attrs:{"label":"name","options":_vm.ercs,"get-option-key":function (option) { return option.id; }},on:{"input":function($event){return _vm.saveFilter($event, 'erc')},"search":function($event){return _vm.onSearch({ name: $event }, 'erc/filter')}}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Institution")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"nameseniorcall"},on:{"change":function($event){return _vm.saveFilter($event.target.value, 'institution')}}})]),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Leave")]),_c('v-select',{attrs:{"label":"label","options":[
                {label: 'All', name: 'Leave - All', value: null},
                {label: 'Yes', name: 'Leave - Yes', value: true},
                {label: 'False', name: 'Leave - False', value: false} ],"get-option-key":function (option) { return option.name; }},on:{"input":function($event){return _vm.saveFilter($event, 'leave')}}})],1),_c('hr'),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Host name")]),_vm._v(" "),_c('v-select',{attrs:{"label":"name","options":_vm.evaluatorsTotal,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')},"input":function($event){return _vm.saveFilter($event, 'host_name')}}})],1)]},proxy:true}])})],1),_c('ViewPeers',{attrs:{"user":_vm.traceUser}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }