<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Assign peers - {{ convo.title }}<br>
                <small
                  id="time-in"
                >Deadline: <strong class="text-danger">{{ convo.end_date_formatted }} {{ convo.end_hour_formatted }}</strong></small>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <CompleteTable
          :sorted-field="sortedField"
          :data="applicants"
          :sort-mapping="sortMapping"
          :title="'ICREAs'"
          :total="applicantsTotal"
          :fetch-path="'convo/promotionReport'"
          :filter-path="'convo/promotionReport'"
        >
          <template v-slot="{field}">
            <td
              class="text-end"
            >
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <i data-feather="more-vertical" />
                </template>
                <div
                  class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                >
                  <!-- <b-dropdown-item @click="$store.dispatch('modals/toggleConvoViewPeers', true), traceUser = field">
                    <i
                      class="me-50"
                      data-feather="eye"
                    /> View peers
                  </b-dropdown-item>
                  <div class="dropdown-divider" /> -->
                  <b-dropdown-item
                    @click="$router.push({name: 'evaluator.calls.evaluations.edit-peers', params: {
                      userPcmember: field,
                      convo: convo,
                    },})"
                  >
                    <i
                      class="me-50"
                      data-feather="edit"
                    /> Manage peers
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </td>
          </template>
          <template #customfilters>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Researcher</label>
              <v-select
                label="name"
                :options="users"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                @input="saveFilter($event, 'user')"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Area</label>
              <v-select
                label="name"
                :options="areas"
                :get-option-key="option => option.id"
                @input="saveFilter($event, 'area_name')"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >ERC</label>
              <v-select
                label="name"
                :options="ercs"
                :get-option-key="option => option.id"
                @input="saveFilter($event, 'erc')"
                @search="onSearch({ name: $event }, 'erc/filter')"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Institution</label><!-- Its a text field because some evaluators might not have parametrized the institution -->
              <input
                type="text"
                class="form-control"
                name="nameseniorcall"
                @change="saveFilter($event.target.value, 'institution')"
              >
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Leave</label>
              <v-select
                label="label"
                :options="[
                  {label: 'All', name: 'Leave - All', value: null},
                  {label: 'Yes', name: 'Leave - Yes', value: true},
                  {label: 'False', name: 'Leave - False', value: false},
                ]"
                :get-option-key="option => option.name"
                @input="saveFilter($event, 'leave')"
              />
            </div>
            <hr>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Host name</label> <!-- Filter by role evaluator -->
              <v-select
                label="name"
                :options="evaluatorsTotal"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')"
                @input="saveFilter($event, 'host_name')"
              />
            </div>
          </template>
        </CompleteTable>
      </div>
      <ViewPeers :user="traceUser" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import ViewPeers from '../../admin/promotion-call/components/ViewPeers.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    CompleteTable,
    vSelect,
    ViewPeers,
  },
  data() {
    return {
      traceUser: {},
      selected: {},
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Erc',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Peers',
          checked: true,
          order: 5,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas',
        Erc: 'erc.code',
        Institution: 'host',
        Peers: 'peers.length',
      },
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      areas: 'areas/areas',
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      users: 'users/fakeUsers',
      ercs: 'erc/items',
      evaluatorsTotal: 'users/evaluators',
    }),
  },
  async mounted() {
    await this.$store.dispatch('convo/getActivePromotion')
    if (!this.convo || this.convo.days === 0) {
      this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
    } else {
      await this.$store.dispatch('convo/promotionIcreas', this.filters)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      if (this.areas.length === 0) {
        this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
      }
      await this.$store.dispatch('modals/fetchUserFields', 'promotion_icreas')
      if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
        await this.$store.dispatch('modals/fetchFields', {
          fields: this.defaultFields,
          table: 'promotion_icreas',
        })
      }
    }
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name && search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
